import Button from 'components/Button';
import { CloseIcon } from 'components/Drawer';
import ThreeStars from 'components/icons/outline/ThreeStars';
import { useTranslation } from 'react-i18next';

export interface DraftMessageProps {
  open: boolean;
  onClose: () => void;
  currentDraft?: any;
}

const DraftMessage = ({ currentDraft, open, onClose }: DraftMessageProps) => {
  const { t } = useTranslation();

  return open ? (
    <div className="p-4 bg-primary-darker text-white">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          {currentDraft?.is_automatic && (
            <ThreeStars className="my-0.5 h-4 w-4 text-white" />
          )}
          <h3 className="text-xs font-medium">
            {t(
              `reports__${
                currentDraft?.is_automatic ? 'automatic' : 'draft'
              }_message_title`
            )}
          </h3>
        </div>
        <Button
          size="custom"
          variant="custom"
          className={`text-white ${
            currentDraft?.is_automatic && 'items-start mt-1'
          }`}
          onClick={onClose}
        >
          <CloseIcon className="h-3 w-3" />
        </Button>
      </div>
      <p className="mt-1 text-xs">
        {t(
          `reports__${
            currentDraft?.is_automatic ? 'automatic' : 'draft'
          }_message_content`
        )}
      </p>
    </div>
  ) : (
    <></>
  );
};

export default DraftMessage;
