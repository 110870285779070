import classNames from 'classnames';
import Button from 'components/Button';
import Form from 'components/Form';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useReport from 'containers/MedicalReport/useReport';
import TextareaAutosize from 'react-autosize-textarea';
import useDocumentation from 'containers/AppointmentMeet/Documentation/useDocumentation';
import LensEmpty from 'components/icons/outline/LensEmpty';
import { usePortal } from 'containers/Shared/components/Portal';
import AnalysisModal from 'containers/MedicalReport/AnalysisModal';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import Tracker from 'utils/Tracking';
import useDebounce from 'utils/useDebounce';
import TrashIcon from 'components/icons/Trash';
import Confirmation from 'components/Modal/Confirmation';
import {
  UpsertReportRequest,
  useDeleteReportMutation,
  useGetAllReportsQuery,
} from 'services/reports';
import { v4 as uuid } from 'uuid';
import DraftMessage from 'containers/MedicalReport/DraftMessage';
import { useDispatch, useSelector } from 'react-redux';
import { updateAutomaticReport } from 'state/medicalReports/index.slice';

export interface Props {
  formMethods: ReturnType<typeof useForm>;
  patientHash: string;
  onClose?: () => void;
  roomId?: number;
  consultation_type?: string;
  consultation_id?: string;
  setTab: (tab: string) => void;
  onAutoSave?: (data: { report_id: string; data: UpsertReportRequest }) => void;
}

export type Fields = {
  objective: string;
  subjective: string;
  analysis?: string;
  diagnostic_id: any;
  plan: string;
  is_automatic?: boolean;
};

const MedicalReportCall: FC<Props> = ({
  formMethods,
  patientHash: patient_hash,
  onClose,
  roomId,
  consultation_type,
  consultation_id,
  setTab,
  onAutoSave,
}) => {
  const { t } = useTranslation();
  const portal = usePortal();
  const {
    register,
    watch,
    formState: { isDirty, dirtyFields, isSubmitting },
    setValue,
    handleSubmit,
  } = formMethods;
  const formData = watch();
  const dispatch = useDispatch();
  const { handleChangeScreen, deleteDraft } = useMedicalReport();
  const { data: reportsData, isLoading: isLoadingReports } =
    useGetAllReportsQuery({ patient_hash }, { skip: !patient_hash });
  const { automaticReport } = useSelector((state: any) => state.medicalReports);
  const currentDraft = reportsData?.draftReport;
  const [diagnostic, setDiagnostic] = useState<any>(
    currentDraft?.diagnostic ?? {}
  );
  const [firstView, setFirstView] = useState<boolean>(true);
  const [mutateDeleteDraft] = useDeleteReportMutation();
  const [draftMessageOpen, setDraftMessageOpen] = useState(!!currentDraft);

  const { get: getDocumentation } = useDocumentation({
    id: consultation_id!,
    type: consultation_type!,
  });
  const [eventName, setEventName] = useState('');

  const reportId = useRef<string>(uuid());

  const handleSaveDraft = (data: Fields) => {
    !isLoadingReports &&
      onAutoSave?.({
        report_id: currentDraft?.uuid ?? reportId.current,
        data: {
          customer_hash: patient_hash,
          consultation_id,
          consultation_type,
          plan: data.plan,
          is_automatic: currentDraft?.is_automatic,
          diagnostic_id: data.diagnostic_id,
          objective_data: data.objective,
          subjective_data: data.subjective,
        },
      });
  };

  const debounceTrackerEvent = useDebounce({
    delay: 1000,
    onDebounce: (value: string) => {
      handleSaveDraft({
        objective: formData.objective,
        subjective: formData.subjective,
        plan: formData.plan,
        diagnostic_id: diagnostic?.id,
      });

      if (value !== '' && eventName) {
        Tracker.event(eventName, {
          event_room_id: roomId,
        });
      }
    },
    skipFirst: true,
  });

  const handleDebounce = (eventName: string) => (e: any) => {
    setEventName(eventName);
    debounceTrackerEvent(e.target.value);
  };

  const { create: createMedicalReport } = useReport({
    patient_hash,
    onSuccess: () => {
      mutateDeleteDraft({ report_id: currentDraft?.uuid ?? reportId.current });
      getDocumentation();
      setTab && setTab('profile');
      handleChangeScreen('profile');
      dispatch(updateAutomaticReport('reset'));
    },
    consultation_type,
    consultation_id,
  });

  const handleSearch = (event: Event) => {
    event?.preventDefault();
    Tracker.event('reports create diagnostic button click', {
      event_room_id: roomId,
    });

    portal.open(
      <AnalysisModal
        onClose={() => {
          portal.close('search_analysis');
        }}
        onSetDiagnostic={(diagnostic) => {
          setDiagnostic(diagnostic);
          setValue('diagnostic_id', diagnostic.id);
          handleSaveDraft({
            objective: formData.objective,
            subjective: formData.subjective,
            plan: formData.plan,
            diagnostic_id: diagnostic.id,
          });
        }}
      />,
      'search_analysis'
    );
  };

  const handleDelete = () => {
    portal.open(
      <Confirmation
        title={t('reports__medical_report_delete_confirmation__title')}
        content={t('reports__medical_report_delete_confirmation__content')}
        confirmText={t(
          'reports__medical_report_delete_confirmation__confirm_text'
        )}
        cancelText={t(
          'reports__medical_report_delete_confirmation__cancel_text'
        )}
        onConfirm={() => {
          deleteDraft(currentDraft);
          currentDraft && mutateDeleteDraft({ report_id: currentDraft?.uuid });
          dispatch(updateAutomaticReport('reset'));
          portal.close('confirmation');
          handleChangeScreen('profile');
        }}
        onCancel={() => portal.close('confirmation')}
      />,
      'confirmation'
    );
  };

  useEffect(() => {
    if (roomId && firstView) {
      Tracker.pageView('reports create form view', {
        event_room_id: roomId,
      });
      setFirstView(false);
    }
  }, []);

  useEffect(() => {
    diagnostic?.text &&
      Tracker.event('reports create diagnostic select option', {
        event_room_id: roomId,
      });
  }, [diagnostic]);

  const isFormDisabled = useMemo(() => {
    if (isSubmitting || !diagnostic?.id) {
      return true;
    }

    // If continuing from a draft, we don't want to wait for isDirty to be true
    if (currentDraft) {
      return false;
    }
    return !isDirty;
  }, [currentDraft, isDirty, diagnostic?.id, isSubmitting, automaticReport]);

  return (
    <>
      <Form
        onSubmit={handleSubmit((fields: Fields) => {
          Tracker.event('reports create save button click', {
            event_room_id: roomId,
          });
          createMedicalReport(fields, onClose);
        })}
        className="flex-1 flex flex-col justify-between h-[calc(100%_-_60px)]"
      >
        <DraftMessage
          currentDraft={currentDraft}
          open={draftMessageOpen}
          onClose={() => setDraftMessageOpen(false)}
        />
        <div className="flex-grow pt-4 px-4 overflow-auto">
          <Field title={t('report__modal_form_field_subjective_title')}>
            <TextArea
              name="subjective"
              disabled={isSubmitting}
              label={t('report__modal_form_field_subjective_label')}
              register={register}
              isDirty={dirtyFields.subjective}
              onChange={handleDebounce('reports create reason field typed')}
            />
          </Field>

          <Field title={t('report__modal_form_field_objective_title')}>
            <TextArea
              name="objective"
              disabled={isSubmitting}
              label={t('report__modal_form_field_objective_label')}
              register={register}
              isDirty={dirtyFields.objective}
              onChange={handleDebounce('reports create symptoms field typed')}
            />
          </Field>

          <Field title={t('report__modal_form_field_analysis_title')}>
            <button
              className="flex flex-row w-full justify-start items-center bg-background py-[18px] px-4 rounded-xl text-gray-dark text-sm "
              onClick={(event: any) => handleSearch(event)}
            >
              <div className="flex flex-row overflow-hidden">
                <LensEmpty className="w-4 mr-1 text-gray-medium shrink-0" />

                {diagnostic.text ? (
                  <span className="w-40 text-dark text-ellipsis truncate">
                    {diagnostic.text}
                  </span>
                ) : (
                  t('report__modal_form_field_analysis_label')
                )}
                <span className="uppercase text-primary ml-2">
                  {diagnostic && diagnostic.code}
                </span>
              </div>
            </button>
            <input
              className="hidden"
              name="diagnostic_id"
              ref={register}
              disabled={isSubmitting}
              defaultValue={formData.diagnostic_id}
              readOnly
            />
            {!formData.diagnostic_id && (
              <span className="text-xs text-negative">
                {t('report__modal_form_field_analysis_required_text')}
              </span>
            )}
          </Field>

          <Field title={t('report__modal_form_field_plan_title')}>
            <TextArea
              name="plan"
              disabled={isSubmitting}
              label={t('report__modal_form_field_plan_label')}
              register={register}
              isDirty={dirtyFields.plan}
              onChange={handleDebounce(
                'reports create recommendations field typed'
              )}
            />
          </Field>
        </div>
        <div className="pb-4 px-4 flex gap-2 pt-6 border-t border-separators">
          {currentDraft && (
            <Button
              className="bg-transparent hover:bg-transparent"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <TrashIcon className="text-gray-dark h-4 w-4" />
            </Button>
          )}
          <Button
            disabled={isFormDisabled}
            loading={isSubmitting}
            size="small"
            // TODO: font-medium is not overridable bc base component uses font-bold.
            // !font-medium is a workaround but should be avoided (maps to important)
            // Proposal: integrate cn utility with tailwindMerge
            className="ml-auto !font-medium text-white bg-primary py-3 px-4"
            type="submit"
          >
            {t('reports__medical_report_submit')}
          </Button>
        </div>
      </Form>
    </>
  );
};

const Field: FC<{ title: string }> = ({ title, children }) => (
  <div className="flex gap-2 mb-4 flex-col">
    <p className="text-sm text-primary-dark font-medium">{title}</p>
    <div>{children}</div>
  </div>
);

const TextArea: FC<{
  name: string;
  disabled: boolean;
  label: string;
  register: any;
  isDirty: boolean;
  onChange?: (a: any) => void;
}> = ({ name, disabled, label, isDirty, register, onChange }) => (
  <div className="flex relative w-full h-full">
    <TextareaAutosize
      disabled={disabled}
      name={name}
      defaultValue=""
      placeholder={label}
      ref={register()}
      rows={isDirty ? 4 : 5}
      className={classNames(
        'bg-background w-full rounded-lg p-2 px-4 placeholder-gray-dark text-primary-darker h-20 text-sm flex-grow min-h-0',
        'focus:outline-none focus-visible:ring-1 focus-visible:ring-primary'
      )}
      onChange={onChange}
      maxRows={15}
    />
  </div>
);

export default MedicalReportCall;
