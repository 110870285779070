import { useState, useEffect } from 'react';
import ClinicalHistoryDisclaimer from 'containers/DetailBar/PatientBar/components/ClinicalHistoryDisclaimer/ClinicalHistoryDisclaimer';
import dayjs from 'dayjs';
import copyToClipboard from 'utils/copyToClipboard';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Link from 'components/icons/outline/Link';
import DoubleCheck from 'components/icons/outline/DoubleCheck';
import Pencil from 'components/icons/Pencil';
import EditPatientModal from 'containers/Room/components/RoomBody/EditPatientModal';
import { usePortal } from 'containers/Shared/components/Portal';
import Button from 'components/Button';
import Skeleton from 'containers/DetailBar/PatientBar/components/PatientProfile/Skeleton';
import { getCountryName } from 'utils/countries';
import usePhoneCall from 'containers/PhoneCall/usePhoneCall';
import { getCanStartPhoneCall } from 'containers/Room/reducers/console.reducer';
import { useSelector } from 'react-redux';
import clsx from 'classnames/bind';
import PhoneIcon from 'components/icons/Phone';
import Organizations from 'containers/DetailBar/PatientBar/components/PatientProfile/Organizations';
import SquareArrowOut from 'components/icons/outline/SquareArrowOut';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import api from 'api';

const formatPhoneNumber = ({ phonePrefix, phone }) =>
  phonePrefix
    ? `${!phonePrefix.includes('+') ? '+' : ''}${phonePrefix} ${phone}`
    : ` ${phone}`;

const classnames = clsx.bind({
  'profile-detail-row': 'text-sm font-light text-gray-dark',
});

const PatientProfile = ({ patient, isLoading, room }) => {
  const [mouseOverName, setMouseOverName] = useState(false);
  const { t } = useTranslation();
  const portal = usePortal();
  const { startPhoneCall } = usePhoneCall();
  const canStartPhoneCall = useSelector(getCanStartPhoneCall);
  const [showPhoneIcon, setShowPhoneIcon] = useState(false);
  const { isAppointment, initialized } = useSelector(
    (state) => state.phoneCall
  );
  const { current } = useSelector((state) => state.appointments);

  const formatAgeFromDate = (date) => {
    const age = dayjs().diff(date, 'year');

    if (age < 2) {
      const months = dayjs().diff(date, 'month');
      return `${months} ${t('sidebar__patient_profile_age_months')}`;
    }

    return `${age} ${t('sidebar__patient_profile_age_years')}`;
  };

  const genderName = (gender) => {
    return t(
      gender === 'female'
        ? 'sidebar__patient_profile_male'
        : 'sidebar__patient_profile_female'
    );
  };

  const handleEditProfile = () => {
    portal.open(
      <EditPatientModal
        onClose={() => {
          portal.close('edit_name_modal');
        }}
        roomId={room.room_id}
        roomTitle={room.title}
        patient={room.customer}
        title={room.title}
        contactId={room.meta.contact_id}
      />,
      'edit_name_modal'
    );
  };

  const handlePhoneCall = () => {
    if (
      canStartPhoneCall &&
      !isAppointment &&
      !initialized &&
      !['phonecall'].includes(current?.channel)
    ) {
      const userHash = room.customer.hash;
      startPhoneCall({ userHash });
    }
  };

  const getLinkToPublicMedicalHistory = () => {
    api.patients.profile
      .getPublicMedicalHistory({ hash: room.customer.hash })
      .then((publicMedicalHistory) => {
        if (publicMedicalHistory?.url) {
          window.open(publicMedicalHistory.data.url, '_blank');
        } else {
          toast.error(
            <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
          );
        }
      })
      .catch(() => {
        toast.error(
          <ErrorToast>{t('shared__errors_generic_message')}</ErrorToast>
        );
      });
  };

  if (!patient || isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="px-4 py-6 border-b border-separators">
      <ClinicalHistoryDisclaimer />

      <div className="mb-4">
        <div className="flex items-center">
          <h3
            className="font-medium pr-3 overflow-x-hidden"
            onMouseOver={() => setMouseOverName(true)}
            onMouseLeave={() => setMouseOverName(false)}
          >
            {room.title}
            <LinkToCopyToClipboard
              show={mouseOverName}
              textToCopy={room?.meta?.hash}
              labelFeedback={t('sidebar__patient_copy_hash_copied')}
              label={t('sidebar__patient_copy_hash_label')}
            />
          </h3>
          {room?.meta?.contact_id && (
            <Button
              className="ml-auto h-6 w-6 p-1"
              onClick={handleEditProfile}
              variant="clear"
              size="custom"
            >
              <Pencil className="w-3 h-3" />
            </Button>
          )}
        </div>
        <p className="text-gray-dark text-md">{patient.email}</p>
      </div>
      <div>
        {patient.tag_id && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_tag_id')}
            </div>
            <div className="w-1/2">{patient.tag_id}</div>
          </div>
        )}
        {patient.birth_date && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_age')}
            </div>
            <div className="w-1/2">{formatAgeFromDate(patient.birth_date)}</div>
          </div>
        )}
        {patient.gender !== null && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_sex')}
            </div>
            <div className="w-1/2">{genderName(patient.gender)}</div>
          </div>
        )}
        {patient.phone && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_phone')}
            </div>
            <div className="w-1/2">
              <button
                className={classnames(
                  'flex flex-row whitespace-nowrap',
                  canStartPhoneCall &&
                    !isAppointment &&
                    !initialized &&
                    !['phonecall'].includes(current?.channel)
                    ? 'text-blue cursor-pointer underline-offset-1'
                    : 'cursor-default'
                )}
                onClick={handlePhoneCall}
                onMouseEnter={() => {
                  canStartPhoneCall &&
                    !isAppointment &&
                    !initialized &&
                    !['phonecall'].includes(current?.channel) &&
                    setShowPhoneIcon(true);
                }}
                onMouseLeave={() => setShowPhoneIcon(false)}
              >
                <span>
                  {formatPhoneNumber({
                    phonePrefix: patient.phone_prefix,
                    phone: patient.phone,
                  })}
                  {canStartPhoneCall &&
                    !isAppointment &&
                    !initialized &&
                    !['phonecall'].includes(current?.channel) && (
                      <div className="border border-blue -translate-y-1" />
                    )}
                </span>
                {showPhoneIcon && (
                  <PhoneIcon className="ml-1.5 text-blue w-[14px]" />
                )}
              </button>
            </div>
          </div>
        )}
        {patient.tax_id && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_tax_id')}
            </div>
            <div className="w-1/2">{patient.tax_id}</div>
          </div>
        )}
        {patient.subscription && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_coverage')}
            </div>
            <div className="w-1/2">
              {patient.coverage_name ?? patient.subscription}
            </div>
          </div>
        )}
        {patient.country_code && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium">
              {t('sidebar__patient_profile_country')}
            </div>
            <div className="w-1/2">
              {getCountryName({ code: patient.country_code })}
            </div>
          </div>
        )}
        {patient.has_public_medical_history && (
          <div className="flex mb-1 text-sm">
            <div className="w-1/2 font-medium ">
              {t('sidebar__patient_profile_medical_history')}
            </div>
            <botton
              onClick={getLinkToPublicMedicalHistory}
              className="flex flex-row w-1/2 whitespace-nowrap text-blue cursor-pointer"
            >
              {t('sidebar__patient_profile_medical_history_button')}
              <SquareArrowOut className="w-4 h-4 ml-1 text-blue" />
            </botton>
          </div>
        )}
        {patient.organizations.length > 0 && (
          <Organizations organizations={patient.organization_guides} />
        )}
      </div>
    </div>
  );
};

const LinkToCopyToClipboard = ({
  show,
  textToCopy,
  label,
  labelFeedback,
  feedbackDelay = 1000,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    show && ReactTooltip.rebuild();
  }, [show]);

  const copyTextToClipboard = () => {
    setShowFeedback(true);
    copyToClipboard(textToCopy);
    setTimeout(() => setShowFeedback(false), feedbackDelay);
  };

  if (!show && !showFeedback) {
    return null;
  }

  return (
    <button
      data-tip={showFeedback ? labelFeedback : label}
      onClick={copyTextToClipboard}
      className="absolute ml-1"
    >
      {showFeedback ? (
        <DoubleCheck className="w-4 h-4" />
      ) : (
        <Link className="w-4 h-4 text-gray-dark" />
      )}
    </button>
  );
};

export default PatientProfile;
