import MainHeader, { Title } from 'pages/main/Header';
import AppointmentMeet from 'containers/AppointmentMeet';
import { useTranslation } from 'react-i18next';

const AppointmentMeets = ({ isVideoCall }) => {
  const { t } = useTranslation();

  return (
    <>
      <MainHeader>
        <Title>
          {isVideoCall
            ? t('appointment_meet_videocall_title')
            : t('appointment_meet_phonecall_title')}
        </Title>
      </MainHeader>
      <main className="flex flex-grow max-h-full overflow-y-hidden w-full min-w-0 bg-white">
        <AppointmentMeet />
      </main>
    </>
  );
};

export default AppointmentMeets;
