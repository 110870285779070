import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'phone-calls/v1';
const BASE_URL = config.services.mediquo.chat_url;

type CallSetupParams = {
  consultationType?: string;
  consultationId?: string;
  userHash: string;
};

const getToken = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/token`,
    headers: getHeaders(),
  });

const callSetup = ({
  userHash,
  consultationType,
  consultationId,
}: CallSetupParams) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/patients/${userHash}`,
    params: {
      consultation_type: consultationType,
      consultation_id: consultationId,
    },
    headers: getHeaders(),
  });

export default {
  getToken,
  callSetup,
};
