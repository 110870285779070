import Button from 'components/Button';
import ArrowDownRecipe from 'components/icons/ArrowDownRecipe';
import useDocumentation from 'containers/AppointmentMeet/Documentation/useDocumentation';
import { useEffect, useState } from 'react';
import Tracker from 'utils/Tracking';
import { usePortal } from 'containers/Shared/components/Portal';
import { useDispatch, useSelector } from 'react-redux';
import { usePrescriptionDrawer } from 'containers/Prescription';
import useDownloadDocument from 'containers/AppointmentMeet/Documentation/useDownloadDocument';
import EmptyFolderBnW from 'components/images/EmptyFolderBnW';
import { useHistory, useParams } from 'react-router';
import useAppointment from 'containers/Appointments/useAppointment';
import { useTranslation } from 'react-i18next';
import Confirmation from 'components/Modal/Confirmation';
import { setNotInCall } from 'containers/WaitingRoom/actions/GetWaitingRoom.actions';
import classNames from 'classnames';
import {
  getCanPrescribe,
  getCanPrescribeDiagnosticTest,
} from 'containers/Inbox/reducers/session.reducer';
import { getCanPatientDiagnosticTestPrescription } from 'containers/Room/reducers/console.reducer';
import FeatureFlag from 'containers/Shared/components/FeatureFlag';
import { DiagnosticTestsDrawer } from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/DiagnosticTestPrescriptionsPanel';
import useDiagnosticTest from 'containers/DetailBar/PatientBar/components/DiagnosticTestPrescriptions/useDiagnosticTest';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import useRoomActions from 'containers/Room/hooks/useRoomActions';
import { joinRoom } from 'containers/Room/actions/JoinRoom.actions';
import useConsultation from 'containers/Consultations/useConsultation';
import Document from 'containers/Appointments/Reporting/Document';
import Pencil from 'components/icons/Pencil';
import Trash from 'components/icons/Trash';
import AddButton from 'containers/Appointments/Reporting/AddButton';
import {
  useDeleteReportMutation,
  useGetAllReportsQuery,
} from 'services/reports';
import { getBriefing } from 'containers/DetailBar/PatientBar/components/Reports/DraftReport';
import dayjs from 'dayjs';
import useAutomaticReport from 'containers/AppointmentMeet/Documentation/useAutomaticReport';
import { toast } from 'react-toastify';
import ErrorToast from 'containers/Shared/components/Toasts/ErrorToast';
import { updateAutomaticReport } from 'state/medicalReports/index.slice';
import PhoneCircleArrow from 'components/icons/outline/PhoneCircleArrow';
import NotPresentModal from 'components/Appointment/NotPresentModal';
import Flag from 'components/icons/outline/Flag';
import FlagSolid from 'components/icons/solid/FlagSolid';

export default function Reporting({
  patient,
  room,
  consultationId,
  consultationType,
  appointment,
  isAppointmentAction,
  closeReporting,
  setProfileCollapsed,
  setTab,
  setStatus,
  isPhoneCall,
  handleJoinCall,
  isReportCancelled,
}) {
  const { appointmentId } = useParams();
  const history = useHistory();
  const portal = usePortal();
  const canCreatePrescriptions = useSelector(getCanPrescribe);
  const canPrescribeDiagnosticTest = useSelector(
    getCanPrescribeDiagnosticTest && getCanPatientDiagnosticTestPrescription
  );
  const { t } = useTranslation();
  const {
    get: getDocumentation,
    documentation,
    isEmpty,
  } = useDocumentation({
    id: appointmentId ?? consultationId,
    type: consultationType,
    isAppointmentAction,
  });

  const publishedDocuments = documentation?.filter(
    (document) => document.status !== 'draft'
  );
  const { openPrescriptionDrawer } = usePrescriptionDrawer();
  const { complete, notPresent } = useAppointment();

  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { handleOnClose } = useDiagnosticTest();
  const { handleChangeScreen } = useMedicalReport();
  const { data: reportsData } = useGetAllReportsQuery(
    { patient_hash: room?.meta?.hash },
    { skip: !room?.meta?.hash }
  );
  const {
    automaticReport,
    automaticReportStatus,
    automaticReportDraft,
    isRecordable,
  } = useSelector((state) => state.medicalReports);
  const currentDraft = reportsData?.draftReport;
  const hasReport = !!publishedDocuments?.find(
    (document) => document.document_type === 'report'
  );
  const { isDetailBarOpen, toggleProfile } = useRoomActions({ room });
  const { summary } = useSelector((state) => state.patientBar);
  const roomPatient = useSelector((state) => state.patientBar.patient);
  const isAppointment = consultationType === 'appointment';
  const { finishChatConsultation } = useConsultation();
  const [hoverCallBack, setHoverCallBack] = useState(false);
  const [hoverNotPresent, setHoverNotPresent] = useState(false);
  const { getAutomaticReport } = useAutomaticReport({
    id: consultationId,
    type: consultationType,
    status: isReportCancelled ? 'cancelled' : automaticReportStatus,
  });

  useEffect(() => {
    appointment?.room_id && dispatch(joinRoom(appointment?.room_id));
  }, []);

  useEffect(() => {
    getDocumentation();
  }, [summary?.reports_count, summary?.prescriptions_added]);

  useEffect(() => {
    if (appointment?.status === 'finished') {
      setIsFinished(true);
    }
  }, [appointment?.status]);

  useEffect(() => {
    if (
      automaticReportStatus === 'error' &&
      !automaticReportDraft &&
      !currentDraft
    ) {
      toast.error(
        <ErrorToast>{t('reports__automatic_message_error')}</ErrorToast>
      );
      dispatch(updateAutomaticReport('reset'));
    }
  }, [automaticReportStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        isReportCancelled ||
        isAppointmentAction ||
        ['cancelled'].includes(automaticReportStatus) ||
        !isRecordable
      ) {
        dispatch(updateAutomaticReport('reset'));
        clearInterval(interval);
        return;
      }
      if (
        automaticReportDraft ||
        ['error', 'finished', 'idle', 'cancelled'].includes(
          automaticReportStatus
        ) ||
        isReportCancelled
      ) {
        clearInterval(interval);
      } else if (automaticReportStatus === 'loading' && !isAppointmentAction) {
        getAutomaticReport();
      }
    }, 3000);
    if (
      automaticReportDraft ||
      ['error', 'finished', 'idle', 'cancelled'].includes(
        automaticReportStatus
      ) ||
      isReportCancelled
    ) {
      clearInterval(interval);
    }
    const timeout = setTimeout(() => {
      if (automaticReportDraft) {
        clearInterval(interval);
      } else if (
        !isRecordable ||
        ['cancelled'].includes(automaticReportStatus) ||
        isReportCancelled
      ) {
        clearInterval(interval);
        dispatch(updateAutomaticReport('reset'));
      } else if (automaticReportStatus === 'loading') {
        clearInterval(interval);
        isAppointmentAction
          ? dispatch(updateAutomaticReport('reset'))
          : dispatch(updateAutomaticReport('error'));
      }
    }, 41000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  const onClosePrescription = () => {
    getDocumentation();
  };

  const openCreateMedicalReport = (isDraft) => {
    !isDraft &&
      Tracker.event('reports create button click', {
        event_room_id: room?.room_id,
      });
    setProfileCollapsed(false);
    setTab('profile');
    !isDetailBarOpen && toggleProfile();
    handleChangeScreen('report');
  };

  const openPrescription = () => {
    openPrescriptionDrawer({
      room,
      consultationType,
      consultationId: appointmentId ?? consultationId,
      onClose: onClosePrescription,
    });
  };

  const closeDiagnosticTest = () => {
    setTimeout(() => {
      handleOnClose();
      onClosePrescription();
      handleChangeScreen('profile');
    }, 3000);
  };

  const openDiagnosticTest = () => {
    portal.open(
      <DiagnosticTestsDrawer
        onClose={closeDiagnosticTest}
        patient={patient}
        consultationType={consultationType}
        consultationId={appointmentId ?? consultationId}
      />,
      'diagnostic_tests_drawer'
    );
  };

  const doFinishConsultation = async () => {
    await finishChatConsultation({
      consultationId: appointmentId ?? consultationId,
    });
    await closeReporting();
    handleChangeScreen('profile');
  };

  const handleOnFinished = () => {
    if (isFinished) {
      closeReporting();
      handleChangeScreen('profile');
      return;
    }

    new Promise(async (resolve, reject) => {
      if (isEmpty) {
        isAppointment
          ? handleEmptyAppointment(resolve, reject)
          : handleEmptyChatConsultation(resolve, reject);
        return;
      }

      if (isAppointment) {
        complete({ appointment });
        dispatch(setNotInCall());
        handleChangeScreen('profile');
        isAppointmentAction
          ? await closeReporting()
          : history.push('/appointments');
        resolve(true);
      } else {
        if (hasReport) {
          await doFinishConsultation();
          resolve(true);
        } else {
          handleEmptyChatConsultation(resolve, reject);
        }
      }
    });
  };

  const handleEmptyAppointment = (resolve, reject) => {
    portal.open(
      <Confirmation
        title={t('appointments__finished_no_report_modal_title')}
        content={t('appointments__finished_no_report_modal_content')}
        cancelText={t('appointments__finished_no_report_modal_cancel')}
        confirmText={t('appointments__finished_no_report_modal_finish')}
        onCancel={() => {
          portal.close('confirmation');
          reject(false);
        }}
        onConfirm={async () => {
          await complete({ appointment });
          await handleChangeScreen('profile');
          await resolve(true);
          await dispatch(setNotInCall());
          await portal.close('confirmation');
          !isAppointmentAction
            ? await history.push('/appointments')
            : await closeReporting();
        }}
      />,
      'confirmation'
    );
  };

  const handleEmptyChatConsultation = (resolve, reject) => {
    // For chat consultations, if the chat has a required report, the user can't finish the consultation without a report.
    // Otherwise, the user should confirm wants to finish without a report
    if (room?.meta?.permissions?.chat?.has_required_report) {
      portal.open(
        <Confirmation
          title={t('chat_consultations__finished_no_report_modal_title')}
          content={t('chat_consultations__finished_no_report_modal_content')}
          confirmText={t('chat_consultations__finished_no_report_modal_finish')}
          onConfirm={() => {
            portal.close('confirmation');
            reject(false);
          }}
        />,
        'confirmation'
      );
    } else {
      portal.open(
        <Confirmation
          title={t('chat_consultations__finished_no_report_modal_title')}
          content={t('appointments__finished_no_report_modal_content')}
          cancelText={t('appointments__finished_no_report_modal_cancel')}
          confirmText={t('appointments__finished_no_report_modal_finish')}
          onCancel={() => {
            portal.close('confirmation');
            reject(false);
          }}
          onConfirm={async () => {
            await doFinishConsultation();
            resolve(true);
            portal.close('confirmation');
          }}
        />,
        'confirmation'
      );
    }
  };
  const markAsNotPresent = async () => {
    await notPresent({ appointmentId: appointment.id });
    await portal.close('modal_not_present');
    await setTimeout(() => {
      isAppointmentAction ? closeReporting() : history.push('/appointments');
    }, 2000);
  };

  const openNotPresentModal = () => {
    portal.open(
      <NotPresentModal
        handleClose={() => portal.close('modal_not_present')}
        handleConfirm={markAsNotPresent}
      />,
      'modal_not_present'
    );
  };

  return (
    <div className="w-full h-full flex items-center px-10 py-8 overflow-y-auto overflow-x-hidden relative">
      <div className="flex flex-col justify-between h-full m-auto items-centerx">
        {isPhoneCall && (
          <span className="absolute top-6 right-6 flex flex-col z-10">
            <button
              className="flex flex-row text-white text-sm items-center"
              onClick={openNotPresentModal}
              onMouseEnter={() => setHoverNotPresent(true)}
              onMouseLeave={() => setHoverNotPresent(false)}
            >
              <div className="w-fit">
                <span
                  className={classNames({
                    'border-b border-white': hoverNotPresent,
                  })}
                >
                  {t('consultations__meet_not_present_button')}
                </span>
              </div>
              <div className="w-8 h-8 bg-[#404040] ml-1 p-2 rounded-full">
                {hoverNotPresent ? (
                  <FlagSolid className="w-4 -translate-y-3 " />
                ) : (
                  <Flag className="w-4" />
                )}
              </div>
            </button>
          </span>
        )}
        <div className="flex m-2" />
        <div className="max-w-[599px] mx-auto flex-col">
          <h3 className="text-sm text-white">
            {t('videocall__finished_patient_text')}:{' '}
            {patient?.name ?? roomPatient?.name}
          </h3>
          <h1 className="text-2xl text-white font-medium">
            {t('videocall__finished_summary_title')}
          </h1>
          <p className="text-sm text-white mb-4 mt-4">
            {t('videocall__finished_summary_text')}
          </p>
          <div className="flex flex-row border-t pt-4 mt-4 border-black">
            <div
              className={classNames(
                'w-full grid gap-2 xs:grid-cols-1 sm:grid-cols-2  mb-4',
                {
                  'xl:grid-cols-3': canPrescribeDiagnosticTest,
                }
              )}
            >
              <AddButton
                endSection={
                  currentDraft && (
                    <div className="text-gray-light font-normal text-sm">
                      {t('videocall__end_section_draft')}
                    </div>
                  )
                }
                onClick={() =>
                  !isRecordable || isReportCancelled
                    ? openCreateMedicalReport()
                    : automaticReportStatus !== 'loading' &&
                      openCreateMedicalReport()
                }
                status={
                  !isRecordable || isReportCancelled
                    ? 'idle'
                    : automaticReportStatus
                }
                isAppointmentAction={isAppointmentAction}
              >
                {t('videocall__finished_document_report')}
              </AddButton>
              <FeatureFlag enabled={canCreatePrescriptions}>
                <AddButton onClick={openPrescription}>
                  {t('videocall__finished_document_prescription')}
                </AddButton>
              </FeatureFlag>
              <FeatureFlag enabled={canPrescribeDiagnosticTest}>
                <AddButton onClick={openDiagnosticTest}>
                  {t('videocall__finished_document_diagnostic_test_button')}
                </AddButton>
              </FeatureFlag>
            </div>
          </div>

          <p className="my-6 text-sm leading-5 font-medium text-white">
            {t('appointments__reporting_sent_documents')}
          </p>

          <div className="max-h-60 h-60 overflow-y-auto mb-5 space-y-2">
            {currentDraft && (
              <DraftItem
                draft={currentDraft}
                automaticReport={automaticReport}
                openMedicalReport={openCreateMedicalReport}
              />
            )}

            {publishedDocuments?.length ? (
              publishedDocuments.map(
                ({ name, url, created_at, document_type }, index) => {
                  return (
                    <Item
                      key={index}
                      url={url}
                      type={document_type}
                      name={name}
                      created_at={created_at}
                    />
                  );
                }
              )
            ) : (
              <div className="flex flex-col h-full items-center justify-center rounded-md">
                <EmptyFolderBnW />
                <p className="flex items-center center justify-center text-white text-sm mt-4 mx-5">
                  {t('videocall__finished_document_empty')}
                </p>
              </div>
            )}
          </div>
        </div>

        <div
          className={classNames(
            'flex mb-12 w-full',
            isPhoneCall ? 'justify-between' : 'justify-end'
          )}
        >
          {isPhoneCall && (
            <button
              className="flex flex-row text-white text-sm items-center gap-2 mb-4"
              onClick={() => {
                setStatus('meet');
                handleJoinCall();
              }}
              onMouseEnter={() => setHoverCallBack(true)}
              onMouseLeave={() => setHoverCallBack(false)}
            >
              <div className="flex items-center justify-center rounded-2xl w-11 h-11 bg-negative-light">
                <PhoneCircleArrow className="w-6 h-6 text-negative" />
              </div>
              <div className="w-fit">
                <span
                  className={classNames({
                    'border-b border-white': hoverCallBack,
                  })}
                >
                  {t('videocall__finished_summary_button_return_phonecall')}
                </span>
              </div>
            </button>
          )}
          <Button
            variant="secondary"
            onClick={handleOnFinished}
            className="tracking-widest py-2.5 px-4 bg-white mb-4"
          >
            {!isFinished
              ? t('videocall__finished_summary_button')
              : t('videocall__finished_summary_button_exit')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const IconButton = ({ children, className, ...props }) => (
  <button
    className={classNames(
      'flex place-items-center justify-center w-5 h-5 bg-background-dark text-secundary group-hover:bg-secundary group-hover:text-primary-darker rounded-md font-normal shrink-0',
      className
    )}
    type="button"
    {...props}
  >
    {children}
  </button>
);

const DraftItem = ({ draft, automaticReport, openMedicalReport }) => {
  const { t } = useTranslation();
  const { handleChangeScreen } = useMedicalReport();
  const [deleteReport] = useDeleteReportMutation();
  const portal = usePortal();
  const dispatch = useDispatch();

  const handleDeleteDraft = () => {
    portal.open(
      <Confirmation
        title={t('reports__medical_report_delete_confirmation__title')}
        content={t('reports__medical_report_delete_confirmation__content')}
        confirmText={t(
          'reports__medical_report_delete_confirmation__confirm_text'
        )}
        cancelText={t(
          'reports__medical_report_delete_confirmation__cancel_text'
        )}
        onConfirm={() => {
          deleteReport({ report_id: draft.uuid });
          automaticReport && dispatch(updateAutomaticReport('reset'));
          portal.close('confirmation');
          handleChangeScreen('profile');
        }}
        onCancel={() => portal.close('confirmation')}
      />,
      'confirmation'
    );
  };

  return (
    <Document
      type={t(
        `sidebar__patient_clinical_courses_${
          draft?.is_automatic ? 'automatic' : 'draft'
        }`
      )}
      title={
        draft?.is_automatic ? dayjs().format('DD/MM/YYYY') : getBriefing(draft)
      }
      actions={
        <div className="inline-flex items-center gap-2">
          <span className="text-sm font-normal">
            {!draft?.is_automatic &&
              t('sidebar__patient_clinical_courses_draft')}
          </span>
          <IconButton onClick={() => openMedicalReport('draft')}>
            <Pencil className="w-2.5" />
          </IconButton>
          <IconButton onClick={handleDeleteDraft}>
            <Trash className="w-2.5" />
          </IconButton>
        </div>
      }
      isAutomatic={draft?.is_automatic}
    />
  );
};

const Item = ({ name, url, type }) => {
  const { download } = useDownloadDocument({ url, name });
  const { t } = useTranslation();

  return (
    <Document
      type={
        {
          report: t('videocall__finished_document_report'),
          prescription: t('videocall__finished_document_prescription'),
          diagnosticTestPrescription: t(
            'videocall__finished_document_diagnostic_test'
          ),
        }[type]
      }
      title={name}
      actions={
        <IconButton onClick={() => download()}>
          <ArrowDownRecipe className="w-4" />
        </IconButton>
      }
    />
  );
};
